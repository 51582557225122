import Vue from "vue";

const validation = Vue.extend({
  methods: {
    validateInputs() {
      this.$validator.validateAll();
    },
    validateState(ref: string | number): boolean | null {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: unreachable code
        this.veeFields[ref] &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: unreachable code
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: unreachable code
        return !this.veeErrors.has(ref);
      }

      return null;
    },
  },
});

export default validation;
