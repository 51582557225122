<template>
  <div class="d-flex h-100 py-5">
    <b-card
      :header="$t('register.header')"
      class="m-auto w-100 rounded-0"
      variant="dark"
      id="signup-card"
    >
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        id="input-group-1"
        :label="$t('general.eMail')"
        label-for="input-1"
      >
        <!-- description="We'll never share your email with anyone else." -->
        <b-form-input
          id="input-1"
          v-model="email"
          type="email"
          required
          :placeholder="$t('general.eMail')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        id="input-group-2"
        :label="$t('register.password')"
        label-for="input-2"
      >
        <b-form-input
          id="input-2"
          v-model="password"
          type="password"
          required
          :placeholder="$t('register.password')"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-button @click="signUp">{{ $t("register.register") }}</b-button>
      </b-form-group>

      <p class="small text-muted">
        {{ $t("register.haveUserAlready") }}
        <router-link to="/login">{{ $t("register.signInHere") }}</router-link>
      </p>
    </b-card>
  </div>
</template>

<script>
import { fireAuth } from "@/firebase";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
export default Vue.extend({
  name: "signUp",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    ...mapActions({
      setUser: "auth/setUser",
      saveVersion: "goals/saveVersion",
    }),
    signUp: function () {
      // firebase
      // .auth()
      fireAuth
        .createUserWithEmailAndPassword(
          fireAuth.getAuth(),
          this.email,
          this.password
        )
        .then(
          (user) => {
            // alert("user: " + user);
            this.setUser({
              user: { email: user.user.email, uid: user.user.uid },
            });
            this.saveVersion({ user: user.user });
            this.$router.replace("/");
          },
          (err) => {
            alert("Oops. " + err.message);
          }
        );
    },
  },
});
</script>

<style scoped>
#signup-card {
  max-width: 500px;
}
</style>
