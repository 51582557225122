<template>
  <div class="d-flex h-100 py-5">
    <b-card
      :header="$t('login.header')"
      class="m-auto w-100 rounded-0"
      variant="dark"
      id="login-card"
    >
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        id="input-group-1"
        :label="$t('general.eMail') | capitalize"
        label-for="input-1"
      >
        <!-- description="We'll never share your email with anyone else." -->
        <b-form-input
          id="email"
          v-model="form.email"
          type="email"
          required
          :placeholder="$t('general.eMail')"
          name="email"
          :data-vv-as="$t('general.eMail')"
          v-validate.immediate="'required|email'"
          :state="validateState('email')"
          aria-describedby="email-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="email-feedback">{{
          veeErrors.first("email")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        id="input-group-2"
        :label="$t('login.password')"
        label-for="input-2"
      >
        <b-form-input
          id="input-2"
          v-model="form.password"
          type="password"
          required
          :placeholder="$t('login.password')"
          name="password"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-button :disabled="veeErrors.all().length > 0" @click="login">
          {{ $t("login.login") }}
        </b-button>
      </b-form-group>

      <p class="small text-muted">
        {{ $t("login.dontHaveUser") }}
        <router-link to="/sign-up">{{ $t("login.registerHere") }}</router-link>
      </p>
    </b-card>
  </div>
</template>

<script type="ts">
import { fireAuth } from "@/firebase";
import { mapActions } from "vuex";
import Vue from "vue";
import validation from "@/mixins/validation";
export default Vue.extend({
  name: "login",
  mixins: [
    validation
  ],
  data() {
    return {
      form: {
        email: "",
        password: "",
      }
    };
  },
  methods: {
    ...mapActions({
      setUser: "auth/setUser",
    }),
    login() {
      fireAuth.signInWithEmailAndPassword(fireAuth.getAuth(), this.form.email, this.form.password)
        .then(
          (user) => {
            this.setUser({
              user: { email: user.user.email, uid: user.user.uid },
            });
            this.$router.replace("/my-goals");
          },
          (err) => {
            alert("Oops. " + err.message);
          }
        );
    },
  },
});
</script>

<style scoped>
#login-card {
  max-width: 500px;
}
</style>
